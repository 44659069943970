import algoliasearchHelper, {
    type PlainSearchParameters,
} from "algoliasearch-helper";
import dayjs from "dayjs";
import { algoliaClient } from "./algolia-client";
import {
    AlgoliaIndexes,
    getAlgoliaIndex,
    SortOption,
} from "./get-algolia-index";
import { dateRangeBuilder } from "./search-refinements";
import { RequestOptions } from "./types/RequestOptions";

export const getAlgoliaSearch = ({
    sort = "Featured",
    attributesToRetrieve,
    distinct = false,
    facets,
    disjunctiveFacets,
    baseIndex,
    filterToFacetMapper,
}: {
    baseIndex: AlgoliaIndexes;
    sort?: SortOption;
    attributesToRetrieve?: PlainSearchParameters["attributesToRetrieve"];
    distinct?: PlainSearchParameters["distinct"];
    facets?: PlainSearchParameters["facets"];
    disjunctiveFacets?: PlainSearchParameters["disjunctiveFacets"];
    filterToFacetMapper: { [key: string]: string | null };
}) => {
    const index = getAlgoliaIndex(baseIndex, sort);

    const searchHelper = algoliasearchHelper(algoliaClient, index, {
        distinct,
        facetingAfterDistinct: true,
        facets,
        disjunctiveFacets,
        attributesToRetrieve,
    });
    const search = async ({
        query,
        page = 0,
        hitsPerPage = 15,
        ruleContexts,
        filters,
    }: RequestOptions) => {
        if (query) {
            searchHelper.setQuery(query);
        }
        if (page) {
            searchHelper.setPage(page);
        }
        if (ruleContexts) {
            searchHelper.setQueryParameter("ruleContexts", ruleContexts);
        }
        Object.entries(filters).forEach(([filter, values]) => {
            const facetKey = filterToFacetMapper[filter];
            if (!facetKey) {
                return;

                // TODO: Allow search to not need certain filers WWB-697
                // throw new Error(`Invalid filter key: ${filter}`);
            }

            if (values) {
                if (Array.isArray(values) && values.length > 0) {
                    values.forEach((value) => {
                        searchHelper.addDisjunctiveFacetRefinement(
                            facetKey,
                            value.toString(),
                        );
                    });
                } else if (
                    typeof values === "string" ||
                    typeof values === "boolean"
                ) {
                    searchHelper.addDisjunctiveFacetRefinement(
                        facetKey,
                        values.toString(),
                    );
                }
                //add numeric and numerical range filters when needed
            }
        });
        if (
            "dates" in filters &&
            typeof filters["dates"] === "object" &&
            filters["dates"] &&
            "from" in filters["dates"] &&
            // TODO: Allow search to not need certain filers WWB-697
            // throw new Error("Invalid filter key: dates");
            filterToFacetMapper["dates"]
        ) {
            const startDate = filters["dates"].from
                ? dayjs(filters["dates"].from).toISOString()
                : undefined;
            const endDate = filters["dates"].to
                ? dayjs(filters["dates"].to).toISOString()
                : undefined;
            dateRangeBuilder(
                filterToFacetMapper["dates"],
                searchHelper,
                startDate,
                endDate,
            );
        }

        const response = await searchHelper.searchOnce({
            page: page,
            hitsPerPage: hitsPerPage,
            distinct,
        });
        return {
            hits: response.content.hits,
            page: response.content.page,
            nbPages: response.content.nbPages,
            nbHits: response.content.nbHits,
            hitsPerPage: response.content.hitsPerPage,
            facets: response.content.facets,
            disjunctiveFacets: response.content.disjunctiveFacets,
        };
    };

    return {
        search,
    };
};
