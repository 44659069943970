import { createContext, useContext } from "react";
import type { ModalProps } from "../Modal";

type ModalContextValue = Pick<ModalProps, "onCloseRequested">;

const ModalContext = createContext<ModalContextValue>(null!);

export type ModalProviderProps = React.PropsWithChildren<ModalContextValue>;

export const ModalContextProvider = ({
    children,
    ...rest
}: ModalProviderProps) => {
    return (
        <ModalContext.Provider value={rest}>{children}</ModalContext.Provider>
    );
};

export const useModalContext = () => {
    return useContext(ModalContext);
};
