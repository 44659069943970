import cn from "clsx";
import React, { CSSProperties, HtmlHTMLAttributes } from "react";
import styles from "./Grid.module.scss";

export type GridAlignment = "left" | "center" | "right";
export type GridProps = React.PropsWithChildren<{
    /**
     * Number of columns on desktop
     * @default 1
     */
    columns?: number;
    /**
     * Number of columns on mobile
     * @default 1
     */
    mobileColumns?: number;
    /**
     * Number of columns on tablet
     * @default 2
     */
    tabletColumns?: number;
    /**
     * Alignment of the last item in the grid
     * @default left
     */
    alignment?: GridAlignment;
    gap?: {
        xs?: string;
        sm?: string;
        md?: string;
        lg?: string;
        xl?: string;
    };
}> &
    HtmlHTMLAttributes<HTMLDivElement>;

export const defaultGridGap = {
    xs: "var(--g-spacing-xl)",
    sm: "var(--g-spacing-xl)",
    md: "var(--g-spacing-xl)",
    lg: "var(--g-spacing-xl)",
    xl: "var(--g-spacing-xl)",
};

export const Grid: React.FC<GridProps> = ({
    columns = 1,
    mobileColumns = 1,
    tabletColumns = 2,
    alignment = "left",
    children,
    className,
    gap,
    style,
    ...otherProps
}) => {
    return (
        <div
            className={cn(styles.grid, className, {
                [styles[`grid--${alignment}`]]: alignment,
            })}
            style={
                {
                    "--grid-column-count": columns,
                    "--grid-mobile-column-count": mobileColumns,
                    "--grid-tablet-column-count": tabletColumns,
                    "--grid-gap-xs": gap?.xs ?? defaultGridGap.xs,
                    "--grid-gap-sm": gap?.sm ?? defaultGridGap.sm,
                    "--grid-gap-md": gap?.md ?? defaultGridGap.md,
                    "--grid-gap-lg": gap?.lg ?? defaultGridGap.lg,
                    "--grid-gap-xl": gap?.xl ?? defaultGridGap.xl,
                    ...style,
                } as CSSProperties
            }
            {...otherProps}
        >
            {children}
        </div>
    );
};
