import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";

type Size = {
    height?: number;
    width?: number;
};

export const getObjectPosition = (asset: AssetStoryblok, size?: Size) => {
    if (!asset.filename || !asset.focus) {
        return null;
    }

    if (size?.height && size.width) {
        return "50% 50%";
    }

    // Asset dimensions looks like (width)x(height)
    const assetDimensions = asset.filename.match(
        /\/(?<width>\d+)x(?<height>\d+)\//,
    )?.groups as { height: string; width: string } | null;

    if (!assetDimensions?.height || !assetDimensions.width) {
        return null;
    }

    const assetHeight = Number(assetDimensions.height);
    const assetWidth = Number(assetDimensions.width);

    // Focus looks like (x1)x(y1):(x2)x(y2)
    // Two x-y coordinates inside the asset
    // We only need the first pair for the focus point
    const assetFocus = asset.focus.match(/^(?<x>\d*)x(?<y>\d*):\d*x\d*$/i)
        ?.groups as { x: string; y: string } | null;

    if (!assetFocus?.x || !assetFocus.y) {
        return null;
    }

    const xPosition = (Number(assetFocus.x) / assetWidth) * 100;
    const yPosition = (Number(assetFocus.y) / assetHeight) * 100;
    return `${xPosition.toFixed()}% ${yPosition.toFixed()}%`;
};
