import { AlgoliaSearchHelper } from "algoliasearch-helper";
import dayjs from "dayjs";

export const dateRangeBuilder = (
    key: string,
    helper: AlgoliaSearchHelper,
    startDate?: string,
    endDate?: string,
): string => {
    const { start, end, hasEndDate, hasStartDate, isRange } = buildDateRange(
        startDate,
        endDate,
    );

    if (isRange) {
        helper.addNumericRefinement(key, ">=", start.unix());
        helper.addNumericRefinement(key, "<=", end.unix());
    } else {
        if (hasStartDate) {
            helper.addNumericRefinement(key, ">=", start.unix());
        }
        if (hasEndDate) {
            helper.addNumericRefinement(key, "<=", end.unix());
        }
    }

    return "";
};
const buildDateRange = (startDate?: string, endDate?: string) => {
    const start = dayjs(startDate);
    const end = dayjs(endDate);
    const hasStartDate = startDate !== undefined && startDate.length > 0;
    const hasEndDate = endDate !== undefined && endDate.length > 0;
    return {
        hasStartDate,
        hasEndDate,
        isRange: hasStartDate && hasEndDate,
        start,
        end,
    };
};
