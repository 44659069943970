import { SVGAttributes, useId } from "react";

export const WishlistHeart: React.FC<SVGAttributes<SVGElement>> = (props) => {
    const idOne = useId();
    const idTwo = useId();

    return (
        <svg
            {...props}
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 72 72"
            height={36}
            width={36}
        >
            <defs>
                <clipPath id={idOne}>
                    <path d="M0 0h72v72H0z" />
                </clipPath>
                <clipPath id={idTwo}>
                    <path d="M0 0h16v14H0z" />
                </clipPath>
            </defs>
            <g clipPath={`url(#${idOne})`}>
                <g
                    clipPath={`url(#${idTwo})`}
                    transform="matrix(2.25 0 0 2.286 18 19.998)"
                    style={{ display: "block" }}
                >
                    <path
                        strokeLinecap="round"
                        strokeLinejoin="round"
                        stroke="#181818"
                        strokeWidth="1.25"
                        d="M5.92-4.94A3.711 3.711 0 0 0 3.32-6C2.34-6 1.4-5.62.71-4.94l-.71.7-.71-.7a3.737 3.737 0 0 0-5.21 0 3.574 3.574 0 0 0 0 5.12l.71.7L0 6 5.21.88l.71-.7C6.61-.5 7-1.42 7-2.38s-.39-1.88-1.08-2.56z"
                        fill="none"
                        transform="translate(8 7)"
                        style={{ display: "block" }}
                    />
                </g>
            </g>
        </svg>
    );
};
