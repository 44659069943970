"use client";

import React from "react";
import { FiX } from "react-icons/fi";
import { Button } from "../button";
import { Text } from "../text";
import { useModalContext } from "./internal/ModalContext";
import styles from "./Modal.module.scss";
import cn from "clsx";

export type ModalHeaderProps = React.PropsWithChildren<{
    /**
     * Displays a close button in the upper right corner of the modal
     * @default true
     */
    displayClose?: boolean;
    /**
     * ClassName to be applied to the header
     */
    className?: string;
}>;
export const ModalHeader = ({
    children,
    displayClose = true,
    className,
}: ModalHeaderProps) => {
    const { onCloseRequested } = useModalContext();

    if (!(displayClose || children)) {
        return null;
    }

    return (
        <Text.Body
            emphasis="1"
            tag="header"
            className={cn(styles.header, className)}
        >
            {children && (
                <div className={styles["header__title"]}>{children}</div>
            )}
            {displayClose && (
                <Button
                    variant="quiet"
                    adornRight={<FiX aria-hidden size={20} />}
                    onClick={onCloseRequested}
                    className={styles.close}
                    type="button"
                >
                    Close
                </Button>
            )}
        </Text.Body>
    );
};
