import cn from "clsx";
import { HtmlHTMLAttributes } from "react";
import styles from "./StatusLabel.module.scss";

export type StatusLabelStatus =
    | "info"
    | "alert"
    | "success"
    | "neutral"
    | "urgent"
    | "important"
    | "deal";

export type StatusLabelProps = {
    /**
     * Determines the style of the status label
     * @default primary
     */
    variant?: "primary" | "secondary";

    /**
     * Determines the size of the status label
     * @default standard
     */
    size?: "standard" | "small";

    /**
     * Determines the color of the status label
     * @default neutral
     */
    status?: StatusLabelStatus;
    /**
     * Status label text
     */
    text?: string;
    /**
     * Status label icon adorned left of the text
     */
    icon?: React.ReactNode;
} & HtmlHTMLAttributes<HTMLSpanElement>;

export const StatusLabel: React.FC<StatusLabelProps> = ({
    variant = "primary",
    status = "neutral",
    size = "standard",
    icon,
    text,
    className,
    ...spanProps
}) => {
    return (
        <span
            {...spanProps}
            className={cn(
                styles["status-label"],
                styles[`${variant}--${status}`],
                styles[`status-label--${size}`],
                className,
            )}
        >
            {icon && (
                <span className={styles["status-label-icon"]}>{icon}</span>
            )}
            {text}
        </span>
    );
};
