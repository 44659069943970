"use client";

import useEmblaCarousel from "embla-carousel-react";
import React, { useEffect, useId, useState } from "react";
import { SliderNavIndicator } from "./SliderNavIndicator";
import styles from "./TourCardImageSlider.module.scss";

type SliderStateType = {
    selectedIndex: number;
    scrollSnaps: number[];
};

export type TourCardImageSliderProps = React.PropsWithChildren<{
    /**
     * Enable swiping navigation
     * @default false
     */
    enableSwipeNavigation?: boolean;
    /**
     * Label for the carousel
     */
    accessibleLabel: string;
    href?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
}>;

export const TourCardImageSlider: React.FC<TourCardImageSliderProps> = ({
    accessibleLabel,
    href,
    onClick,
    children,
    enableSwipeNavigation,
    ...rest
}) => {
    const sliderId = useId();
    const [emblaRef, emblaApi] = useEmblaCarousel({
        loop: true,
        watchDrag: enableSwipeNavigation,
    });
    const [sliderState, setSliderState] = useState<SliderStateType>({
        selectedIndex: 0,
        scrollSnaps: [],
    });

    useEffect(() => {
        if (!emblaApi) {
            return;
        }

        const updateSliderState = () => {
            const selectedIndex = emblaApi.selectedScrollSnap();
            setSliderState((prevSliderState) => ({
                ...prevSliderState,
                selectedIndex,
                scrollSnaps: emblaApi.scrollSnapList(),
            }));
        };

        updateSliderState();
        emblaApi.on("reInit", () => updateSliderState());
        emblaApi.on("select", () => updateSliderState());

        return () => {
            emblaApi.off("reInit", () => updateSliderState());
            emblaApi.off("select", () => updateSliderState());
        };
    }, [emblaApi]);

    const nbItems = React.Children.count(children);

    return (
        <div
            {...rest}
            className={styles.viewport}
            ref={emblaRef}
            role="group"
            aria-roledescription="carousel"
            aria-label={accessibleLabel}
        >
            <div
                className={styles.track}
                aria-live="polite"
                style={
                    {
                        "--nb-items": `${nbItems}`,
                    } as React.CSSProperties
                }
            >
                {React.Children.map(children, (child, index) => {
                    return (
                        <div
                            className={styles.slide}
                            id={`${sliderId}_${index}`}
                            key={`${sliderId}_${index}`}
                            aria-hidden={
                                emblaApi?.selectedScrollSnap() !== index ||
                                undefined
                            }
                            role="group"
                            aria-roledescription="slide"
                            aria-label={`Image ${index + 1} of ${nbItems}`}
                        >
                            {href && (
                                <a
                                    href={href}
                                    key={`${sliderId}_link_${index}`}
                                    tabIndex={-1}
                                    aria-hidden={true}
                                    className={styles["slide-link"]}
                                    onClick={(e) => onClick?.(e)}
                                />
                            )}
                            {child}
                        </div>
                    );
                })}
            </div>

            {sliderState.scrollSnaps.length > 0 && (
                <SliderNavIndicator
                    scrollPrev={() => emblaApi?.scrollPrev()}
                    scrollNext={() => emblaApi?.scrollNext()}
                    scrollSnaps={sliderState.scrollSnaps}
                    selectedIndex={sliderState.selectedIndex}
                />
            )}
        </div>
    );
};
