import { Button, Rating, StatusLabel, Text } from "@wojo/ui";
import cn from "clsx";
import pluralize from "pluralize";
import React from "react";
import type { TourCardProps } from "../TourCard";
import styles from "./TourCardContent.module.scss";
import { getProductBadgeIcon } from "../../../client/get-product-badge-icon";
export type TourCardContentProps = Partial<TourCardProps>;

export const TourCardContent: React.FC<TourCardContentProps> = (props) => {
    const {
        tourBadge,
        extensionName,
        forceDesktopLayout = false,
        subtitle,
        priceFormatted,
        salePriceFormatted,
        nbCities,
        nbDays,
        nbDaysWithExtension,
        rating,
        name,
        variant,
        priceBadge,
    } = props;

    let subtitlePart = "";
    if (subtitle) {
        subtitlePart = ` ${subtitle}`;
    } else if (extensionName) {
        subtitlePart = `with ${extensionName}`;
    }
    const extensionPart =
        nbDaysWithExtension && extensionName
            ? ` | ${nbDaysWithExtension} ${pluralize(
                  "day",
                  nbDaysWithExtension,
              )} ${subtitlePart} extension`
            : "";
    const cityPart = nbCities
        ? `, ${nbCities} ${pluralize("city", nbCities)}`
        : "";
    const duration = `${nbDays} ${pluralize("day", nbDays)}${
        extensionPart || cityPart
    }`;

    return (
        <div
            className={cn(styles["tour-card-content"], {
                [styles[`tour-card-content--${variant}`]]: !forceDesktopLayout,
                [styles["tour-card-content--desktop"]]: forceDesktopLayout,
            })}
        >
            <div className={styles["tour-card-header"]}>
                {tourBadge && (
                    <span className={styles["tour-card-content-badge"]}>
                        <StatusLabel
                            icon={getProductBadgeIcon(tourBadge.icon)}
                            status={tourBadge.variant}
                            text={tourBadge.text}
                        />
                    </span>
                )}

                <Text.Body className={styles["tour-card-title"]}>
                    {name}
                </Text.Body>

                <Text.Body className={styles["tour-card-duration"]}>
                    {duration}
                </Text.Body>

                {!!rating && !priceBadge && (
                    <Rating
                        className={styles["tour-card-rating"]}
                        value={rating}
                        variant="bold"
                        size="small"
                    />
                )}
            </div>

            <div className={styles["tour-card-footer"]}>
                <div>
                    {salePriceFormatted && (
                        <>
                            <div className="visually-hidden">
                                {`On sale starting from ${salePriceFormatted}. Originally ${priceFormatted}.`}
                            </div>
                            <div
                                className={styles["tour-card-price"]}
                                aria-hidden
                            >
                                From
                                <span className={styles["strike-through"]}>
                                    {priceFormatted}
                                </span>
                                <span className={styles["sale-price"]}>
                                    {salePriceFormatted}
                                </span>
                            </div>
                        </>
                    )}
                    {priceFormatted && !salePriceFormatted && (
                        <div className={styles["tour-card-price"]}>
                            From
                            <span>{priceFormatted}</span>
                        </div>
                    )}
                    {priceBadge && (
                        <div className={styles["price-badge-wrapper"]}>
                            <StatusLabel
                                status={priceBadge.variant}
                                text={priceBadge.text}
                                size="small"
                            />
                        </div>
                    )}
                </div>
                <Button
                    className={styles["tour-card-cta"]}
                    variant="quiet"
                    tag="span"
                >
                    View tour
                </Button>
            </div>
        </div>
    );
};
