import cn from "clsx";
import { HtmlHTMLAttributes, ReactNode, useId } from "react";
import { Star } from "../shared/Star";
import styles from "./Rating.module.scss";

export type RatingProps = {
    /**
     * Determines the style of the rating
     * @default standard
     */
    variant?: "bold" | "standard";

    /**
     * Number of stars to be filled. Aggregated rating value
     * @default 0
     */
    value?: number;

    /**
     * Element to align to the right of the rating
     */
    adornRight?: ReactNode;

    /**
     * The size of the rating stars
     * @default small
     */
    size?: "small" | "medium" | number;
} & HtmlHTMLAttributes<HTMLSpanElement>;

/// Utility functions
function getStarSize(size: string | number): number {
    switch (size) {
        case "small":
            return 14;
        case "medium":
            return 18;
        default:
            return typeof size === "number" ? size : 14;
    }
}
function getStarOffset(starValue: number, ratingValue: number): string {
    if (ratingValue - starValue > 1) {
        return "100%";
    } else if (ratingValue - starValue < 0) {
        return "0%";
    } else {
        return `${(ratingValue - starValue) * 100}%`;
    }
}

export const Rating: React.FC<RatingProps> = ({
    adornRight,
    size = "small",
    value = 0,
    variant = "standard",
    className,
    ...rest
}) => {
    const altText = `${parseFloat(value.toFixed(1))} out of 5 stars`;
    const ariaLabelId = useId();
    const starsGroupClassName = cn(
        styles[`stars-group--${variant}`],
        styles["stars-group"],
    );

    return (
        <div className={cn(styles["wrapper"], className)} {...rest}>
            <p id={ariaLabelId} className="visually-hidden">
                {altText}
            </p>
            <span
                className={starsGroupClassName}
                aria-labelledby={ariaLabelId}
                aria-hidden="true"
            >
                {[...Array(5)].map((_, star) => {
                    return (
                        <Star
                            key={star}
                            size={getStarSize(size)}
                            starOffset={getStarOffset(star, value)}
                        />
                    );
                })}
            </span>
            {adornRight}
        </div>
    );
};
