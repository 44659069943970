type WistiaOEmbed = {
    version: string;
    type: string;
    html: string;
    width: number;
    height: number;
    provider_name: string;
    provider_url: string;
    title: string;
    thumbnail_url: string;
    thumbnail_width: number;
    thumbnail_height: number;
};

type VideoMetadata = {
    height: number;
    url: string;
    width: number;
    videoTitle: string;
};

export async function fetchVideoMetadata(
    videoId?: string,
): Promise<VideoMetadata | null> {
    if (!videoId) {
        return null;
    }
    const response = await fetch(
        `https://fast.wistia.com/oembed?url=http://home.wistia.com/medias/${videoId}&format=json`,
    );

    if (!response.ok) {
        throw new Error("Failed to fetch video details");
    }

    const data: WistiaOEmbed = await response.json();
    return {
        height: data.thumbnail_height,
        width: data.thumbnail_width,
        url: data.thumbnail_url,
        videoTitle: data.title,
    };
}
