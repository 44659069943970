export type TransformAssetOptions = {
    /** Image file type */
    fileType?: string;
    /** Image size */
    size?:
        | {
              height?: number;
              width: number;
          }
        | {
              height: number;
              width?: number;
          };
    /** Transform image to grayscale */
    isGrayscale?: boolean;
    /** Auto-resize image */
    fitIn?: boolean;
};

/**
 * Transforms an asset through the core asset service based on the option arg.
 * Analogous to `transformImage` for Storyblok images.
 *
 * Be sure to configure `process.env.NEXT_PUBLIC_IMAGE_SERVICE_URL` in the consuming application.
 */
export const transformAsset = (
    image: string | null,
    option: TransformAssetOptions,
) => {
    if (!image) {
        return "";
    }

    const options: string[] = [];
    const { fileType, size, fitIn, isGrayscale } = option;
    if (fitIn) {
        options.push("fit-in");
    }
    if (size?.width || size?.height) {
        options.push(`${size.width ?? 0}x${size.height ?? 0}`);
    }
    if (fileType) {
        options.push(`filters:format(${fileType})`);
    }
    if (isGrayscale) {
        options.push("filters:grayscale()");
    }

    try {
        const path = new URL(image).pathname;
        return (
            process.env.NEXT_PUBLIC_IMAGE_SERVICE_URL + options.join("/") + path
        );
    } catch {
        return "";
    }
};
