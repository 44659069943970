"use client";

import { useMediaQuery } from "@mantine/hooks";
import React from "react";
import { AssetStoryblok } from "../../../types/storyblok/asset-storyblok";
import { StoryblokImage } from "../../storyblok-image";
import { TourCardImageSlider } from "../tour-card-image-slider/TourCardImageSlider";
import styles from "./TourCardMedia.module.scss";

export type TourCardMediaProps = {
    images: AssetStoryblok[];
    variant: "slider" | "grid";
    forceDesktopLayout?: boolean;
    tourName: string;
    href?: string;
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};
type ImageType = AssetStoryblok & { kind?: string; image?: AssetStoryblok };

export function TourCardMedia({
    images,
    variant,
    forceDesktopLayout,
    tourName,
    href,
    onClick,
}: TourCardMediaProps) {
    const mobileImageWidth = 560;
    const mobileImageHeight = Math.round(mobileImageWidth / 1.5); // 3:2 aspect ratio

    const tabletBreakpoint = "(min-width: 768px)";
    const isTabletUp = useMediaQuery(tabletBreakpoint, false);
    const shouldShowSingleImage =
        !isTabletUp && variant === "grid" && !forceDesktopLayout;

    if (!images.length) {
        return null;
    }
    const processedImages: ImageType[] = images.map((image) => {
        if ("kind" in image && "image" in image) {
            return image.image as ImageType;
        } else {
            return image as ImageType;
        }
    });

    const filteredImages: ImageType[] = processedImages.filter(
        (image) => image.kind !== "tour-video",
    );

    return (
        <>
            {filteredImages.length === 1 || shouldShowSingleImage ? (
                <>
                    <a
                        href={href}
                        onClick={(e) => onClick?.(e)}
                        aria-hidden={true}
                        tabIndex={-1}
                        className={styles["tour-card-image-link"]}
                    />
                    <StoryblokImage
                        className={styles["tour-card-image"]}
                        mobile={{
                            asset: filteredImages[0],
                            size: {
                                width: mobileImageWidth,
                                height: mobileImageHeight,
                            },
                        }}
                    />
                </>
            ) : (
                <TourCardImageSlider
                    enableSwipeNavigation={variant !== "slider"}
                    accessibleLabel={`Images of ${tourName}`}
                    href={href}
                    onClick={onClick}
                >
                    {filteredImages.map((image, index) => (
                        <StoryblokImage
                            key={index}
                            mobile={{
                                asset: image,
                                size: {
                                    width: mobileImageWidth,
                                    height: mobileImageHeight,
                                },
                            }}
                        />
                    ))}
                </TourCardImageSlider>
            )}
        </>
    );
}
