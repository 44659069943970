import { AlgoliaIndexes, SortOption } from "./get-algolia-index";
import { getAlgoliaSearch } from "./get-algolia-search";
import { tourPageFilterToFacet } from "./types/FiltersToFacets";
import { RequestOptions } from "./types/RequestOptions";
import { UseAlgoliaResponse } from "./types/SearchResults";

export const getTourPagesSearchQuery = ({
    query,
    page = 0,
    hitsPerPage = 15,
    ruleContexts,
    filters,
    distinct,
}: RequestOptions): (() => Promise<UseAlgoliaResponse<"tourPage">>) => {
    const { sort, page: removePage, ...realFilters } = filters;
    const { search } = getAlgoliaSearch({
        baseIndex: AlgoliaIndexes.TOURPAGES,
        sort: sort as SortOption,
        filterToFacetMapper: tourPageFilterToFacet,
        disjunctiveFacets: [
            "activityLevel.name",
            "countries",
            "cities",
            "continents",
            "interestsAndActivities",
            "travelStyles",
            "priceRanges",
            "discountAmount",
            "tourLengthRange",
            "tourCode",
            "classLevel.name",
        ],
    });
    return () =>
        search({
            query,
            page,
            hitsPerPage,
            ruleContexts,
            filters: realFilters,
            distinct,
        });
};
