"use client";

import { useI18n } from "@wojo/localization";
// TODO: we want to deprecate wojo/lottie in favor of react-lottie-player for icons
// as part of https://ef-wojo.atlassian.net/browse/WWB-276
import { LottieIcon } from "@wojo/lottie";
import { Button, Modal, Text } from "@wojo/ui";
import cn from "clsx";
import { FC, useState } from "react";
import lottieSrc from "./lottie-icon-wojo-wishlist-heart-pulse.json";
import styles from "./WishlistButton.module.scss";
import { WishlistHeart } from "./WishlistHeart";

export type WishlistButtonProps = {
    /**
     * Name of the wishlist item
     */
    tourName: string | null;

    /**
     * Is this tour in the current customer's wishlist
     */
    isInWishlist: boolean;

    /**
     * Add or remove this tour from the current customer's wishlist
     */
    toggleWishlist: () => void;
};

export const WishlistButton: FC<WishlistButtonProps> = ({
    isInWishlist,
    tourName,
    toggleWishlist,
}) => {
    const [isModalOpen, setIsModalOpen] = useState(false);
    const i18n = useI18n("storyblok").storyblok;
    return (
        <>
            <button
                className={cn(styles["wishlist-button"], {
                    [styles["wishlist-button--selected"]]: isInWishlist,
                })}
                onClick={() => {
                    isInWishlist ? setIsModalOpen(true) : toggleWishlist();
                }}
                aria-checked={!!isInWishlist}
                role="switch"
                type="button"
            >
                <span className="visually-hidden">
                    {i18n.wishlist.addTourToWishlist.l({
                        tourName: tourName || "",
                    })}
                </span>

                <WishlistHeart
                    aria-hidden
                    className={styles["wishlist-placeholder-icon"]}
                />

                <LottieIcon
                    aria-hidden
                    className={styles["wishlist-lottie-icon"]}
                    data={lottieSrc}
                    checked={!!isInWishlist}
                    height={36}
                    width={36}
                />
            </button>
            <Modal
                isOpen={isModalOpen}
                accessibleTitle={i18n.wishlist.a11y.removeTourFromWishlist.l({
                    tourName: tourName || "",
                })}
                onCloseRequested={() => {
                    setIsModalOpen(false);
                }}
            >
                <div className={styles["modal-content"]}>
                    <Text.Heading className={styles["modal-title"]}>
                        {i18n.wishlist.removeTourFromWishlist.l()}
                    </Text.Heading>
                    <div className={styles["modal-actions"]}>
                        <Button
                            variant="primary"
                            type="button"
                            onClick={() => {
                                toggleWishlist();
                                setIsModalOpen(false);
                            }}
                        >
                            {i18n.wishlist.yesRemoveLabel.l()}
                        </Button>
                        <Button
                            variant="quiet"
                            type="button"
                            onClick={() => {
                                setIsModalOpen(false);
                            }}
                        >
                            {i18n.wishlist.noKeepLabel.l()}
                        </Button>
                    </div>
                </div>
            </Modal>
        </>
    );
};
