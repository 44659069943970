import { SVGAttributes, useId } from "react";
import styles from "./Star.module.scss";

type StarProps = {
    size: number;
    starOffset: string;
    accessibleTitle?: string;
} & SVGAttributes<SVGElement>;

export const Star: React.FC<StarProps> = ({
    size,
    starOffset,
    accessibleTitle,
}) => {
    const id = useId();
    return (
        <svg
            viewBox="0 0 15 14"
            className={styles.star}
            height={size}
            width={size}
            fill="none"
            aria-hidden="true"
            focusable="false"
            xmlns="http://www.w3.org/2000/svg"
        >
            {accessibleTitle && <title>{accessibleTitle}</title>}
            <defs>
                <linearGradient id={id}>
                    <stop offset={0} className={styles["fill-color"]} />
                    <stop
                        className={styles["fill-color"]}
                        offset={starOffset}
                    />
                    <stop
                        className={styles["empty-color"]}
                        offset={starOffset}
                    />
                    <stop className={styles["empty-color"]} offset={100} />
                </linearGradient>
            </defs>
            <g fill={`url(#${id})`} fillRule="evenodd">
                <path
                    clipRule="evenodd"
                    fillRule="evenodd"
                    strokeLinecap="round"
                    strokeLinejoin="round"
                    strokeWidth="1.1"
                    d="M7.99353 1.3319C7.90093 1.144 7.70965 1.02501 7.50018 1.02501C7.2907 1.02501 7.09942 1.144 7.00683 1.3319L5.37481 4.64371L1.7255 5.178C1.5185 5.20831 1.34663 5.35342 1.28204 5.55241C1.21746 5.75139 1.27135 5.96978 1.42109 6.11588L4.06181 8.69223L3.43856 12.3322C3.40321 12.5386 3.48815 12.7472 3.65763 12.8701C3.82711 12.9931 4.05173 13.0092 4.237 12.9116L7.50018 11.1927L10.7634 12.9116C10.9486 13.0092 11.1732 12.9931 11.3427 12.8701C11.5122 12.7472 11.5971 12.5386 11.5618 12.3322L10.9385 8.69223L13.5793 6.11588C13.729 5.96978 13.7829 5.75139 13.7183 5.55241C13.6537 5.35342 13.4818 5.20831 13.2749 5.178L9.62554 4.64371L7.99353 1.3319Z"
                />
            </g>
        </svg>
    );
};
