import { Card } from "@wojo/ui";
import cn from "clsx";
import React from "react";
import { ContentAsset } from "../../../generated/graphql";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { WishlistButton } from "../wishlist-button/WishlistButton";
import { TourCardContent } from "./tour-card-content/TourCardContent";
import { TourCardMedia } from "./tour-card-media/TourCardMedia";
import styles from "./TourCard.module.scss";

export type TourCardProps = {
    /**
     * The variant of the tour card.
     * @default slider
     */
    variant?: "slider" | "grid";
    /**
     * Should desktop layout be forced.
     * @default false
     */
    forceDesktopLayout?: boolean;
    /**
     * The first image for the tour card.
     */
    cardImage?: ContentAsset | AssetStoryblok | null;
    /**
     * The map image for the tour card.
     */
    mapImage?: ContentAsset | AssetStoryblok | null;
    /**
     * The remaining tour card images.
     */
    assets: ContentAsset[] | AssetStoryblok[] | null;
    /**
     * The nb of days for the tour.
     */
    nbDays: number;
    /**
     * The name of the tour.
     */
    name: string;
    /**
     * The subtitle of the tour.
     */
    subtitle?: string;
    /**
     * The name for the extension.
     */
    extensionName?: string;
    /**
     * The url to navigate to when the tour card is clicked.
     */
    url?: string;
    /**
     * The unique code identifier.
     */
    tourCode?: string;
    /**
     * The number of days + the number of extension days.
     */
    nbDaysWithExtension?: number;
    /**
     * The number of cities in tour.
     */
    nbCities?: number;
    /**
     * The aggregate rating of the tour shown as stars.
     */
    rating?: number;
    /**
     * The price formatted.
     */
    priceFormatted: string;
    /**
     * The sale price formatted.
     */
    salePriceFormatted?: string | null;
    /**
     * Special text that shows as badge.
     */
    tourBadge?: {
        text: string;
        variant: "important" | "urgent";
        icon?: string;
    } | null;
    /**
     * Badge that calls attention to the price
     */
    priceBadge?: {
        text: string;
        variant: "important" | "urgent";
        icon?: string;
    } | null;
    /**
     * Is this tour in the current customer's wishlist
     */
    isInWishlist: boolean;
    /**
     * Add or remove this tour from the current customer's wishlist
     */
    toggleWishlist: () => void;
    /**
     * Content text click
     */
    onClick?: (e: React.MouseEvent<HTMLAnchorElement>) => void;
};

export const TourCard: React.FC<TourCardProps> = (props) => {
    const {
        tourBadge,
        extensionName,
        forceDesktopLayout = false,
        priceFormatted,
        salePriceFormatted,
        assets,
        cardImage,
        mapImage,
        isInWishlist,
        nbCities,
        nbDays,
        nbDaysWithExtension,
        rating,
        name,
        subtitle,
        url,
        toggleWishlist,
        variant = "slider",
        onClick,
        priceBadge,
    } = props;

    const mappedImages = [
        cardImage,
        mapImage,
        ...(assets?.slice(1, 3) ?? []),
    ].filter((image) => !!image);

    return (
        <Card
            className={cn(styles["tour-card"], {
                [styles[`tour-card--${variant}`]]: !forceDesktopLayout,
                [styles["tour-card--desktop"]]: forceDesktopLayout,
            })}
        >
            <div className={styles["tour-card-content-wrapper"]}>
                <a
                    className={styles["tour-card-content-link"]}
                    href={url}
                    onClick={(e) => onClick?.(e)}
                >
                    <div className={styles["tour-card-focus-ring"]} />
                    <TourCardContent
                        extensionName={extensionName}
                        subtitle={subtitle}
                        priceFormatted={priceFormatted}
                        salePriceFormatted={salePriceFormatted}
                        nbCities={nbCities}
                        forceDesktopLayout={forceDesktopLayout}
                        nbDays={nbDays}
                        rating={rating}
                        tourBadge={tourBadge}
                        name={name}
                        variant={variant}
                        nbDaysWithExtension={nbDaysWithExtension}
                        priceBadge={priceBadge}
                    />
                </a>
            </div>

            <span className={styles["tour-card-wishlist-button-wrapper"]}>
                <WishlistButton
                    isInWishlist={isInWishlist}
                    tourName={name}
                    toggleWishlist={toggleWishlist}
                />
            </span>

            <div className={styles["tour-card-media-wrapper"]}>
                <TourCardMedia
                    images={mappedImages as AssetStoryblok[]}
                    variant={variant}
                    forceDesktopLayout={forceDesktopLayout}
                    tourName={name}
                    href={url}
                    onClick={onClick}
                />
            </div>
        </Card>
    );
};
