import { useSuspenseQuery } from "@tanstack/react-query";
import { fetchVideoMetadata } from "./fetch-video-metadata";

export const useVideoMetadata = (videoId?: string) => {
    return useSuspenseQuery({
        queryKey: ["videoThumbnail", videoId],
        queryFn: () => fetchVideoMetadata(videoId),
        refetchOnReconnect: false,
        refetchOnMount: false,
        refetchOnWindowFocus: false,
    });
};
