"use client";

import { useMediaQuery } from "@mantine/hooks";
import { Image, type ImageProps } from "@wojo/ui";
import cn from "clsx";
import { forwardRef } from "react";
import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";
import { getObjectPosition } from "./get-object-position";
import styles from "./StoryblokImage.module.scss";
import { transformImage } from "./transform-image";

type AssetProps = {
    asset: AssetStoryblok;
    /**
     * The default desktop width is 1232 * 2.
     * 1232px is the max width of desktop layouts, multiple by 2 for high density screens.
     *
     * The default mobile width is 768 * 2.
     * 768px is the max width of mobile layouts, multiple by 2 for high density screens.
     */
    size?:
        | {
              height?: number;
              width: number;
          }
        | {
              height: number;
              width?: number;
          };
};

export type StoryblokImageProps = Omit<
    ImageProps,
    "sources" | "alt" | "src"
> & {
    /**
     * Image displayed by default
     */
    mobile: AssetProps;

    /**
     * Image displayed on desktop
     */
    desktop?: AssetProps;

    /**
     * Force this image to cover its parent container
     */
    coverContainer?: boolean;
};

const breakpointDesktop = "(min-width: 768px)";

// 1232px is the max width of desktop layouts, multiple by 2 for high density screens
const desktopImageWidth = 1232 * 2;
// 768px is the max width of mobile layouts, multiple by 2 for high density screens
const mobileImageWidth = 768 * 2;

export const StoryblokImage = forwardRef<HTMLImageElement, StoryblokImageProps>(
    ({ coverContainer, desktop, mobile, ...imageProps }, ref) => {
        const isDesktop = useMediaQuery(breakpointDesktop, false);

        const mobileImage = transformImage({
            asset: mobile.asset,
            size: mobile.size ?? { width: mobileImageWidth },
        });

        const desktopAsset = desktop ? desktop.asset : mobile.asset;
        const desktopSize = desktop
            ? desktop.size ?? { width: desktopImageWidth }
            : mobile.size ?? { width: desktopImageWidth };
        const desktopImage = transformImage({
            asset: desktopAsset,
            size: desktopSize,
        });

        const mobilePosition = getObjectPosition(mobile.asset, mobile.size);
        const desktopPosition = desktop
            ? getObjectPosition(desktop.asset, desktop.size)
            : getObjectPosition(mobile.asset, mobile.size);

        return (
            <Image
                {...imageProps}
                src={mobileImage}
                sources={
                    desktopImage
                        ? [
                              {
                                  srcSet: desktopImage,
                                  media: breakpointDesktop,
                              },
                          ]
                        : undefined
                }
                alt={
                    isDesktop
                        ? desktop?.asset.alt || mobile.asset.alt || ""
                        : mobile.asset.alt || ""
                }
                ref={ref}
                className={cn(imageProps.className, {
                    [styles["cover-container"]]: coverContainer,
                })}
                style={
                    coverContainer
                        ? ({
                              ...imageProps.style,
                              "--object-position": mobilePosition ?? undefined,
                              "--object-position-desktop":
                                  desktopPosition ?? undefined,
                          } as React.CSSProperties)
                        : imageProps.style
                }
            />
        );
    },
);

StoryblokImage.displayName = "StoryblokImage";
