import React from "react";
import {
    LuAlarmClock,
    LuBellRing,
    LuClock,
    LuZap,
    LuSparkle,
    LuSparkles,
} from "react-icons/lu";

/**
 * Gets corresponding Lucide icon from wojo-sb-iconpicker plugin.
 * @param icon - The icon name
 * @returns Lucide React icon component
 */
export function getProductBadgeIcon(icon?: string): React.ReactNode {
    if (!icon) {
        return null;
    }

    switch (icon) {
        case "alarmClock":
            return <LuAlarmClock aria-hidden />;
        case "bellRinging":
            return <LuBellRing aria-hidden />;
        case "clock":
            return <LuClock aria-hidden />;
        case "lightningBolt":
            return <LuZap aria-hidden />;
        case "sparkle":
            return <LuSparkle aria-hidden />;
        case "sparkles":
            return <LuSparkles aria-hidden />;
        default:
            return null;
    }
}
