import React from "react";

export type ImageConfig = {
    srcSet: string;
    media?: string;
    type?: "image/webp" | "image/jpeg" | "image/png";
};

type InnerProps = React.DetailedHTMLProps<
    React.ImgHTMLAttributes<HTMLImageElement>,
    HTMLImageElement
>;

export type ImageProps = InnerProps & {
    /**
     * Supports standard srcSet properties `srcSet` `media` `type`
     */
    sources?: ImageConfig[];
    /**
     * Delays loading the image until it's in the viewport
     */
    defer?: boolean;
    /**
     * Class name for the image as opposed to the picture element
     */
    imageClassName?: string;
};

export const Image = React.forwardRef<HTMLImageElement, ImageProps>(
    (
        {
            sources,
            className,
            src,
            imageClassName,
            alt = "",
            defer = true,
            ...props
        },
        ref,
    ) => {
        return (
            <picture className={className}>
                {sources?.map(({ type, media, ...srcProps }, index) => {
                    return (
                        <source
                            data-testid="image-src"
                            srcSet={srcProps.srcSet}
                            type={type}
                            media={media}
                            key={index}
                        />
                    );
                })}
                <img
                    className={imageClassName}
                    {...props}
                    alt={alt}
                    src={src}
                    ref={ref}
                    loading={defer ? "lazy" : "eager"}
                />
            </picture>
        );
    },
);

Image.displayName = "Image";
