import { AssetStoryblok } from "../../types/storyblok/asset-storyblok";

const imageServiceUrlRegex =
    /^(https?:)?\/\/a(-us)?.storyblok.com\/f\/\d+\/(?<width>\d+)x(?<height>\d+)/;

const getIntrinsicSize = (url: string): [number, number] => {
    const { width = "0", height = "0" } =
        imageServiceUrlRegex.exec(url)?.groups ?? {};
    return [parseInt(width), parseInt(height)];
};

export type TransformImageArgs = {
    asset?: AssetStoryblok;
    size?:
        | {
              height?: number;
              width: number;
          }
        | {
              height: number;
              width?: number;
          };
};

/**
 * @summary optimizes the passed storyblok asset if the storyblok image service supports optimizing it
 */
export const transformImage = ({ asset, size }: TransformImageArgs) => {
    if (!asset || !asset.filename) {
        return undefined;
    }
    const formatMatch = asset.filename.match(/.(?<format>gif|jpe?g|png|webp)$/i)
        ?.groups?.format;
    if (!formatMatch) {
        return asset.filename;
    }

    const [intrinsicWidth, intrinsicHeight] = getIntrinsicSize(asset.filename);

    let width: number = size?.width || intrinsicWidth;
    let height: number = size?.height || intrinsicHeight;

    // Calculate the aspect ratio based on the provided size dimensions
    if (size?.width && size.height) {
        const aspectRatio = size.width / size.height;

        // Clamp width and height to intrinsic dimensions while maintaining aspect ratio
        if (width > intrinsicWidth) {
            width = intrinsicWidth;
            height = Math.round(width / aspectRatio);
        }
        if (height > intrinsicHeight) {
            height = intrinsicHeight;
            width = Math.round(height * aspectRatio);
        }
    } else {
        // If only one dimension is provided, calculate the other based on the aspect ratio
        if (size?.width && !size.height) {
            width = Math.min(size.width, intrinsicWidth);
            height = Math.round((intrinsicHeight / intrinsicWidth) * width);
        } else if (!size?.width && size?.height) {
            height = Math.min(size.height, intrinsicHeight);
            width = Math.round((intrinsicWidth / intrinsicHeight) * height);
        }
    }

    const dimensions = `/${width}x${height}`;
    const focalPoint = asset.focus ? `/filters:focal(${asset.focus})` : "";

    return `${asset.filename}/m${dimensions}${focalPoint}`;
};
