export const tourPageFilterToFacet: { [key: string]: string | null } = {
    activityLevel: "activityLevel.name",
    dates: "dates",
    destinations: "countries",
    duration: "tourLengthRange",
    price: "priceRanges",
    sort: "sort",
    discount: "discountAmount",
    tourCode: "tourCode",
    tripType: "classLevel.name",
};
export const departuresFilterToFacet: { [key: string]: string | null } = {
    activityLevel: "activityLevel.name",
    dates: "departureDate",
    destinations: "countries",
    duration: "tourLengthRange",
    price: "priceRanges",
    sort: "sort",
    discount: "discountAmount",
    tourCode: "tourCode",
    tripType: "classLevel.name",
    hasExtensionAvailable: "hasExtensionAvailable",
    isOnSale: "isOnSale",
    status: "status",
    gatewayName: "gateways.displayName",
};
export const blogPostFilterToFacet: { [key: string]: string | null } = {
    activityLevel: "activityLevel.name",
    dates: "dates",
    destinations: "countries",
    duration: "tourLengthRange",
    price: "priceRanges",
    sort: "sort",
    discount: "discountAmount",
    tourCode: "tourCode",
    tripType: "classLevel.name",
};
