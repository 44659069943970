"use client";

import {
    FloatingFocusManager,
    FloatingOverlay,
    useDismiss,
    useFloating,
    useInteractions,
    useRole,
} from "@floating-ui/react";
import cn from "clsx";
import { forwardRef, useEffect, useState } from "react";
import { createPortal } from "react-dom";
import { ModalContextProvider } from "./internal/ModalContext";
import styles from "./Modal.module.scss";
import { ModalHeader } from "./ModalHeader";

export type ModalProps = React.PropsWithChildren<
    {
        /**
         * The visible state of the modal
         */
        isOpen: boolean;
        /**
         * Callback function that is triggered by actions that typically close the modal
         * (e.g. close button, outside click, esc key press)
         * @returns {void}
         */
        onCloseRequested: () => void;
        /**
         * Display options
         * @default overlay
         */
        variant?: "overlay" | "fullscreen";
        /**
         * Allow the modal to be closed using options other than the assigned close button
         * (e.g. backdrop click, esc key press)
         * @default true
         */
        closeOnOutsideClick?: boolean;
        /**
         * Hide the default header of Modal, use ModalHeader as a replacement if needed
         */
        hideDefaultHeader?: boolean;
        /**
         * Prevents submit events within the modal from bubbling
         * @see https://github.com/facebook/react/issues/27555
         */
        stopPropagation?: boolean;
        /**
         * Disables modal translate in opening animation
         */
        disableModalTranslate?: boolean;
        /**
         * The classname to apply to the dialog of the modal
         */
        contentClassName?: string;
    } & (
        | {
              /**
               * A short programmatic name of the modal that describes its purpose.
               * This is for accessibility and will not be visible on the page.
               * NOTE: Please do not use "modal" or "dialog" in the title
               */
              accessibleTitle: string;
          }
        | {
              accessibleTitleId: string;
          }
    )
> &
    React.HTMLAttributes<HTMLDialogElement>;

export const Modal = forwardRef<HTMLDivElement, ModalProps>(function Modal(
    {
        isOpen,
        onCloseRequested,
        closeOnOutsideClick = true,
        variant = "overlay",
        children,
        hideDefaultHeader,
        stopPropagation = false,
        disableModalTranslate = false,
        contentClassName,
        style,
        ...rest
    },
    ref,
) {
    const [isClientRendered, setIsClientRendered] = useState(false);
    const { refs, context } = useFloating({
        open: isOpen,
        onOpenChange: onCloseRequested,
    });
    const { getFloatingProps } = useInteractions([
        useDismiss(context, {
            enabled: closeOnOutsideClick,
            outsidePressEvent: "click",
            outsidePress(event) {
                // allow scrollbar drag
                return refs.floating.current?.parentElement === event.target;
            },
        }),
        useRole(context),
    ]);
    const classnames = cn(styles.modal, styles[`modal--${variant}`], {
        [styles["modal--open"]]: isOpen,
    });

    useEffect(() => {
        setIsClientRendered(true);
    }, []);

    if (!isOpen || !isClientRendered) {
        return null;
    }

    const accessibleTitle =
        "accessibleTitle" in rest ? rest.accessibleTitle : undefined;
    const accessibleTitleId =
        "accessibleTitleId" in rest ? rest.accessibleTitleId : undefined;

    return createPortal(
        <FloatingOverlay
            lockScroll
            className={classnames}
            ref={ref}
            data-testid="modalOverlay"
            onSubmit={
                stopPropagation
                    ? (e) => {
                          e.stopPropagation();
                      }
                    : undefined
            }
        >
            <FloatingFocusManager context={context} modal={isOpen}>
                <dialog
                    className={cn(
                        styles.content,
                        {
                            [styles["content--disable-modal-translate"]]:
                                disableModalTranslate,
                        },
                        contentClassName,
                    )}
                    {...getFloatingProps()}
                    ref={refs.setFloating}
                    aria-label={accessibleTitle || undefined}
                    aria-labelledby={accessibleTitleId || undefined}
                    aria-modal={isOpen}
                    open={isOpen}
                    style={style}
                >
                    <ModalContextProvider onCloseRequested={onCloseRequested}>
                        {!hideDefaultHeader && <ModalHeader displayClose />}
                        {children}
                    </ModalContextProvider>
                </dialog>
            </FloatingFocusManager>
        </FloatingOverlay>,
        document.body,
    );
});
