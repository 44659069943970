import cn from "clsx";
import lottie, { AnimationItem } from "lottie-web";
import React, { useEffect, useRef } from "react";
import { usePrevious } from "../../hooks/usePrevious";
import styles from "./LottieIcon.module.scss";

export type LottieIconProps = {
    /** Start playing on mount */
    autoplay?: boolean;
    /** Should it play over and over again? */
    loop?: boolean;
    /** Animation Data */
    data?: unknown;
    className?: string;
    /** Width of element */
    width?: string | number;
    /** Height of element */
    height?: string | number;
    /** Force override fill and stroke, this is mainly assumes all icons should be the same stroke and fill */
    color?: string;
    /** Which end should this be on? */
    checked?: boolean;
    /** Use a path to a json file instead of data */
    path?: string;
    /** Handle when the icon finishes initial loading */
    onLoaded?: () => void;
};
export const LottieIcon: React.FC<LottieIconProps> = ({
    autoplay = false,
    loop = false,
    width = "1em",
    height = "1em",
    color = "currentColor",
    data,
    className,
    checked = false,
    path,
    onLoaded,
}) => {
    const ref = useRef<HTMLDivElement>(null);
    const lottieRef = useRef<AnimationItem>();
    const prev = usePrevious(checked);
    useEffect(() => {
        if (ref.current) {
            lottieRef.current = lottie.loadAnimation({
                container: ref.current,
                renderer: "svg",
                loop,
                autoplay,
                animationData: data,
                path,
            });
            lottieRef.current.addEventListener("DOMLoaded", () => {
                onLoaded?.();
                if (checked) {
                    lottieRef.current?.setDirection(-1);
                    lottieRef.current?.goToAndStop(
                        lottieRef.current.totalFrames,
                        true,
                    );
                }
            });
            return () => {
                lottieRef.current?.destroy();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (lottieRef.current) {
            const animation = lottieRef.current;
            animation.autoplay = autoplay;
            animation.loop = loop;
        }
    });

    useEffect(() => {
        if (lottieRef.current && prev !== undefined) {
            if (!checked) {
                lottieRef.current.setDirection(-1);
                lottieRef.current.goToAndPlay(
                    lottieRef.current.totalFrames,
                    true,
                );
            } else {
                lottieRef.current.setDirection(1);
                lottieRef.current.goToAndPlay(0, true);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [checked]);

    return (
        <div
            ref={ref}
            className={cn(className, {
                [styles["custom-color"]]: color,
            })}
            style={{ width, height, ["--lottie-color" as string]: color }}
        />
    );
};
