export enum AlgoliaIndexes {
    DEPARTURES = "DEPARTURES",
    TOURPAGES = "TOURPAGES",
    PAGES = "PAGES",
    BLOGPOSTS = "BLOGPOSTS",
}

export type SortOption =
    | "Featured"
    | "Price: low to high"
    | "Rating: high to low"
    | "Duration: short to long"
    | "Duration: long to short"
    | "Discount: high to low"
    | "Departs: soonest to latest"
    | "Departs: latest to soonest";

export const sortToIndexMapping: Record<SortOption, string> = {
    Featured: "",
    "Price: low to high": "_LOWEST_PRICE",
    "Rating: high to low": "_HIGHEST_RATED",
    "Duration: short to long": "_SHORTEST",
    "Duration: long to short": "_LONGEST",
    "Discount: high to low": "_HIGHEST_DISCOUNT",
    "Departs: soonest to latest": "_EARLIEST_DEPARTURE",
    "Departs: latest to soonest": "_LATEST_DEPARTURE",
};

export const getAlgoliaIndex = (
    indexPrefix: AlgoliaIndexes,
    sort: SortOption = "Featured",
) => {
    const indexBase = `${indexPrefix}_${process.env.NEXT_PUBLIC_MARKET_CODE}_${process.env.NEXT_PUBLIC_BUSINESS_CODE}`;
    return indexBase + (sortToIndexMapping[sort] || "");
};
