import { AlgoliaIndexes, SortOption } from "./get-algolia-index";
import { getAlgoliaSearch } from "./get-algolia-search";
import { blogPostFilterToFacet } from "./types/FiltersToFacets";
import { RequestOptions } from "./types/RequestOptions";
import { UseAlgoliaResponse } from "./types/SearchResults";

export const getBlogPostsSearchQuery = ({
    query,
    page = 0,
    hitsPerPage = 15,
    ruleContexts,
    filters,
    distinct,
}: RequestOptions): (() => Promise<UseAlgoliaResponse<"blogPost">>) => {
    //intentionally remove page from filters
    const { sort, page: removePage, ...realFilters } = filters;
    const { search } = getAlgoliaSearch({
        baseIndex: AlgoliaIndexes.BLOGPOSTS,
        sort: sort as SortOption,
        filterToFacetMapper: blogPostFilterToFacet,
        disjunctiveFacets: [],
    });
    return () =>
        search({
            query,
            page,
            hitsPerPage,
            ruleContexts,
            filters: realFilters,
            distinct,
        });
};
